import Breadcrumbs from "common/components/Breadcrumbs";
import Container from "common/components/Container";
import SEO from "common/components/SEO";
import DefaultLayout from "common/layouts/Default";
import React from "react";

const StrojenieINaprawa: React.FC = () => {
  return (
    <DefaultLayout>
      <SEO
        title="Strojenie i naprawa"
        description="Strojenie, regulacje i naprawy mechanizmów, naprawy wszystkich podzespołów, ocena stanu technicznego, wycena fortepianów i pianin."
      />
      <Breadcrumbs />

      <div className="h-screen bg-[url('../../../static/images/tuning-and-repairs-pattern.png')] bg-cover bg-center py-16 sm:py-32">
        <Container customClasses="text-center lg:max-w-3xl 2xl:max-w-6xl lg:!px-0 mx-auto relative">
          <h1 className="text-3xl font-semibold md:text-4xl lg:text-5xl">
            Strojenie i naprawa fortepianów i pianin
          </h1>
          <p className="mt-6 text-lg md:text-xl lg:mt-8 lg:text-2xl">
            Zapraszam do skorzystania z moich usług{" "}
            <span className="font-semibold text-pink-300">strojenia</span>,{" "}
            <span className="font-semibold text-zinc-300">
              regulacji, napraw mechanizmów oraz wszystkich podzespołów
            </span>
            . Przeprowadzić mogę{" "}
            <span className="font-semibold text-green-300">
              ocenę stanu technicznego
            </span>{" "}
            oraz <span className="font-semibold text-amber-300">wycenę</span>{" "}
            instrumentu.
          </p>
          <p className="mt-8 text-zinc-600 lg:text-lg">
            Dodatkowych informacji udzielę telefonicznie lub mailowo.
          </p>
        </Container>
      </div>
    </DefaultLayout>
  );
};

export default StrojenieINaprawa;
