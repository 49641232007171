import { ChevronRightIcon, HomeIcon } from "@heroicons/react/solid";
import { Location } from "@reach/router";
import Container from "common/components/Container";
import { Link as GatsbyLink } from "gatsby";
import React from "react";

const Breadcrumbs: React.FC = () => {
  return (
    <Location>
      {({ location }) => {
        const links = {
          "/": "Home",
          "/sklep": "Sklep internetowy",
          "/strojenie-i-naprawa": "Strojenie i naprawa",
          "/renowacje": "Renowacje",
        };

        let pathname = location.pathname;
        let pageTitle;

        if (pathname.slice(-1) === "/") {
          pageTitle = links[pathname.slice(0, -1) as keyof typeof links];
        } else {
          pageTitle = links[pathname as keyof typeof links];
        }

        return (
          <Container customClasses="mb-4 font-semibold lg:text-lg flex items-center gap-1">
            <GatsbyLink to="/" className="group" aria-label="Return to home">
              <HomeIcon className="h-5 w-5 group-hover:text-zinc-300" />
            </GatsbyLink>
            <ChevronRightIcon className="h-5 w-5" />
            <span className="text-zinc-300">{pageTitle}</span>
          </Container>
        );
      }}
    </Location>
  );
};

export default Breadcrumbs;
